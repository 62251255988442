export function printDate(date) {
    if(date === undefined) {
        return undefined
    }
    if(typeof date == 'string') {
        return date
    }
    if(typeof date == 'number') {
        // assuming UTC timestamp (unix date)
        date = new Date(date);
    }
    const utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(utc).toISOString().substring(0, 19).replace("T", " ")
}

export function printCurrency(number) {
    return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}