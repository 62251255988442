import {get_role_credentials, getPreferredCredentials, listAccountsFiltered} from "./authentication";
import {
    AthenaClient,
    GetQueryExecutionCommand,
    GetQueryResultsCommand,
    ListDatabasesCommand,
    ListDataCatalogsCommand,
    ListTableMetadataCommand,
    StartQueryExecutionCommand, StopQueryExecutionCommand
} from "@aws-sdk/client-athena";
import {CloudFrontClient, GetDistributionCommand} from "@aws-sdk/client-cloudfront";
import {
    DescribeLoadBalancerAttributesCommand,
    ElasticLoadBalancingV2Client
} from "@aws-sdk/client-elastic-load-balancing-v2";
import {load_template, remove_children} from "./templating";
import {updateUrl} from "./main";

export async function show_athena_view(page) {
    const accountId = page.parameters.accountId
    const region = page.parameters.region
    if (page.path === "/athena/") {
        await show_athena_query_view(page)
    } else if (page.path === "/athena/redirect") {
        const resource = page.parameters.resource
        const credentials = await get_role_credentials(accountId)
        const client = new AthenaClient({credentials: credentials, region: region})
        let database = null
        let table = null
        let query = null
        if (resource.startsWith("arn:aws:elasticloadbalancing:")) { // ELBv2
            const elbClient = new ElasticLoadBalancingV2Client({credentials: credentials, region: region})
            const attributes = await elbClient.send(new DescribeLoadBalancerAttributesCommand({LoadBalancerArn: resource}))
            const logBucket = attributes.Attributes.find(o => o.Key === "access_logs.s3.bucket")

            if (logBucket == null) {
                throw Error("Loadbalancer attribute 'access_logs.s3.bucket' not set. No logs are written.")
            } else if (logBucket.Value === "elb-logs-v2.eu-west.aws.komoot.de") { // old style
                const today = new Date().toISOString().substring(0, 10).replaceAll("-", "/")
                const elbName = resource.substring(`arn:aws:elasticloadbalancing:${region}:${accountId}:loadbalancer/`.length)
                database = "aws_infrastructure"
                table = "elbv2"
                query = `SELECT time, request_verb, request_url, elb_status_code, user_agent, *
                         FROM ${database}.${table}
                         WHERE day = '${today}' AND elb = '${elbName}' LIMIT 200`
            } else if (logBucket.Value.startsWith("aws-default-logfiles-")) {
                // find target location the ELB is reporting to
                const logPrefix = attributes.Attributes.find(o => o.Key === "access_logs.s3.prefix")

                //could be more generic but for now we assume a fixed pattern 'elbv2/${stackName}'
                //in most cases the logPrefix is the elbv2/{stackName} but just to be sure we extract it here
                const match = logPrefix.Value.match(/elbv2\/(.*)/)
                if (match.length === 2) {
                    const elbName = resource.substring(`arn:aws:elasticloadbalancing:${region}:${accountId}:loadbalancer/`.length)
                    window.location.href = `https://log-inspector.komoot.io/?region=eu-west-1&account=${accountId}&catalog=AwsDataCatalog&database=aws_logs&table=elb_logs_by_stack_name&partitions=stack_name%3D${match[1]}%26region%3D${region}&filters=elb%3D${elbName}`
                    return
                }
            } else {
                throw Error(`Cannot find athena table for 'access_logs.s3.bucket'='{logBucket}'`)
            }
        } else if (/^\w+$/.test(resource)) { // CloudFront distribution
            const cloudFrontClient = new CloudFrontClient({credentials: credentials, region: region})
            const cloudFrontDistribution = await cloudFrontClient.send(new GetDistributionCommand({Id: resource}))
            const host = cloudFrontDistribution.Distribution.DomainName
            const logging = cloudFrontDistribution.Distribution.DistributionConfig.Logging
            if (logging != null && logging.Enabled === true && logging.Prefix != null) {
                //could be more generic but for now we assume a fixed pattern 'cloudfront/${stackName}'
                //in most cases the logPrefix is the cloudfront/{stackName} but just to be sure we extract it here
                const match = logging.Prefix.match(/cloudfront\/(.*)\//)
                if (match.length === 2) {
                    database = "aws_logs"
                    table = "cloudfront_logs_by_stack_name"
                    query = `-- WARNING this table is NOT PARTITIONED by date. Queries take long and are expensive
                    
                             SELECT * FROM ${database}.${table}
                             WHERE stack_name = '${match[1]}'
                             AND host = '${host}'                   -- only needed if you have multiple distributions
                             LIMIT 200;`
                }
            } else {
                throw Error("Invalid logging configuration for CloudFront distribution " + resource)
            }
        }
        if (query == null) {
            throw Error("Unable to construct athena query for aws resource " + resource)
        } else {
            query = query.replace("s/^\s+/gm", "") // trim whitespace
            console.log("Athena sql query: " + query)
            client.send(new StartQueryExecutionCommand({
                QueryString: query,
                WorkGroup: 'elb_logs'
            })).then(async response => {
                console.log("Started initial Athena query:", response)
                window.location.href = `#/athena/?account_id=${accountId}&result=${response.QueryExecutionId}&region=${region}&database=${database}&table=${table}`
            })
        }
    }

}

export async function show_athena_query_view(page) {
    const rootTemplate = load_template('athena_query_view', 'content_holder')
    const accounts = await listAccountsFiltered()
    const accountList = []
    for (const [account, map] of Object.entries(accounts)) {
        const selected = (account === page.parameters.account_id)
        accountList.push({account_id: account, account_name: map.accountName, selected: selected})
    }
    accountList.sort((a, b) => (a.account_name > b.account_name) ? 1 : -1)
    rootTemplate.insert('athena_query_view_header', undefined, {
        accounts: accountList,
        regions: ['eu-west-1', 'eu-central-1', 'us-east-1']
    })
    // register function
    const e_account = document.getElementById('athena_query_view_header_account')
    const e_region = document.getElementById('athena_query_view_header_region')
    const e_catalog = document.getElementById('athena_query_view_header_catalog')
    const e_database = document.getElementById('athena_query_view_header_database')
    const e_table = document.getElementById('athena_query_view_header_table')

    e_account.onchange = function (ev) {
        updateUrl({"account_id": e_account.value})
        refresh(e_account, e_region, e_catalog, e_database, e_table, rootTemplate, 'catalog')
    }
    e_region.onchange = function (ev) {
        updateUrl({"region": e_region.value})
        refresh(e_account, e_region, e_catalog, e_database, e_table, rootTemplate, 'catalog')
    }
    e_catalog.onchange = function (ev) {
        refresh(e_account, e_region, e_catalog, e_database, e_table, rootTemplate, 'database')
    }
    e_database.onchange = function (ev) {
        refresh(e_account, e_region, e_catalog, e_database, e_table, rootTemplate, 'table')
    }
    e_table.onchange = function (ev) {
        refresh(e_account, e_region, e_catalog, e_database, e_table, rootTemplate, undefined)
    }
    e_account.onchange(undefined) // trigger once

    const e_query_cancel = document.getElementById('athena_query_cancel')
    const e_query_start = document.getElementById('athena_query_start')

    const cancelQuery = async function cancel_query(region, account_id, result_id) {
        const credentials = getPreferredCredentials(account_id)
        const client = new AthenaClient({credentials: credentials, region: region})
        try {
            const response = await client.send(new StopQueryExecutionCommand({
                QueryExecutionId: result_id
            }))
            updateUrl({'result': ''})
        } catch (e) {
            document.getElementById('athena_query_view_result_error').innerText = e.message
            throw e
        } finally {
            e_query_start.disabled = false
            e_query_cancel.disabled = true
            e_query_cancel.onclick = undefined
        }
    }

    e_query_start.onclick = async function (ev) {
        e_query_start.disabled = true
        e_query_cancel.disabled = false
        document.getElementById('athena_query_view_result_error').innerText = '' // clean error
        const region = e_region.value
        const account_id = e_account.value
        const credentials = getPreferredCredentials(account_id)
        const query = document.getElementById('athena_query').value
        const client = new AthenaClient({credentials: credentials, region: region})
        try {
            const response = await client.send(new StartQueryExecutionCommand({
                QueryString: query,
                WorkGroup: 'elb_logs'
            }))
            const result_id = response.QueryExecutionId
            updateUrl({'result': result_id})
            e_query_cancel.onclick = function (ev) {
                cancelQuery(region, account_id, result_id, ev)
            }
            await show_query_results(account_id, region, result_id, rootTemplate)
        } catch (e) {
            e_query_start.disabled = false
            e_query_cancel.disabled = true
            if(e.name === "InvalidRequestException") {
                document.getElementById('athena_query_view_result_error').innerText = e.AthenaErrorCode + ": " + e.message
            } else {
                throw e
            }
        } finally {
            // in any case, remove any previous results
            remove_children('athena_query_view_result_head', 'tr')
            remove_children('athena_query_view_result_body', 'tr')
        }
    }

    if(page.parameters.result != null && page.parameters.result.length > 10 && page.parameters.account_id != null && page.parameters.region != null) {
        await show_query_results(page.parameters.account_id, page.parameters.region, page.parameters.result, rootTemplate, true)
    }
}

async function show_query_results(account_id, region, result_id, rootTemplate, overwriteQuery = false) {
    const credentials = getPreferredCredentials(account_id)
    const client = new AthenaClient({credentials: credentials, region: region})

    const executionResponse = await client.send(new GetQueryExecutionCommand({
        QueryExecutionId: result_id
    }))
    if(overwriteQuery) {
        document.getElementById('athena_query').value = executionResponse.QueryExecution.Query
    }
    document.getElementById('athena_query_status').textContent = executionResponse.QueryExecution.Status.State
    switch (executionResponse.QueryExecution.Status.State) {
        case "QUEUED":
        case "RUNNING":
            setTimeout(() => show_query_results(account_id, region, result_id, rootTemplate), 1000)
            return
        case "CANCELLED":
            break
        case "FAILED":
            const err = executionResponse.QueryExecution.Status.AthenaError.ErrorMessage
            document.getElementById('athena_query_view_result_error').innerText = err
            break
        case "SUCCEEDED":
            let row_number = -1 // next is the header
            let nextToken = null
            do {
                console.info("Fetching next page for query id=" + result_id)
                const response = await client.send(new GetQueryResultsCommand({
                    QueryExecutionId: result_id,
                    NextToken: nextToken
                }))
                if (row_number === -1) {
                    rootTemplate.append('athena_query_view_result_head_columns', {
                        row: response.ResultSet.ResultSetMetadata.ColumnInfo
                    })
                }
                for (const row of response.ResultSet.Rows) {
                    if(row_number >= 0) {
                        rootTemplate.append('athena_query_view_result_body_row', {rowNumber: row_number, row: row.Data})
                    }
                    row_number++
                }
                nextToken = response.NextToken
            } while (nextToken != null)
    }
    const e_query_start = document.getElementById('athena_query_start')
    const e_query_cancel = document.getElementById('athena_query_cancel')
    e_query_start.disabled = false
    e_query_cancel.disabled = true
}

async function refresh(element_accounts, element_regions, element_catalogs, element_databases, element_tables, rootTemplate, change_type) {
    const region = element_regions.value
    const account_id = element_accounts.value
    const credentials = get_role_credentials(account_id)
    const client = new AthenaClient({credentials: credentials, region: region})

    switch (change_type) {
        case 'catalog':
            element_catalogs.innerHTML = ''
            let nextTokenCatalogs = null
            do {
                const responseCatalogs = await client.send(new ListDataCatalogsCommand({NextToken: nextTokenCatalogs}))
                for (const dataCatalogsSummary of responseCatalogs.DataCatalogsSummary) {
                    element_catalogs.innerHTML += "<option>" + dataCatalogsSummary.CatalogName + "</option>"
                }
                nextTokenCatalogs = responseCatalogs.NextToken
            } while (nextTokenCatalogs !== undefined)
        case 'database':
            element_databases.innerHTML = ''
            let nextTokenDatabases = null
            do {
                const responseDatabases = await client.send(new ListDatabasesCommand({
                    NextToken: nextTokenDatabases,
                    CatalogName: element_catalogs.value
                }))
                for (const database of responseDatabases.DatabaseList) {
                    element_databases.innerHTML += "<option>" + database.Name + "</option>"
                }
                nextTokenDatabases = responseDatabases.NextToken
            } while (nextTokenDatabases !== undefined)

        case 'table':
            element_tables.innerHTML = ''
            if (element_databases.value !== '') {
                let nextTokenTables = null
                do {
                    const responseTables = await client.send(new ListTableMetadataCommand({
                        NextToken: nextTokenTables,
                        CatalogName: element_catalogs.value,
                        DatabaseName: element_databases.value
                    }))

                    for (const table of responseTables.TableMetadataList) {
                        element_tables.innerHTML += "<option>" + table.Name + "</option>"
                    }
                    nextTokenTables = responseTables.NextToken
                } while (nextTokenTables !== undefined)
            }
    }
}