import {DescribeParametersCommand, SSMClient,} from "@aws-sdk/client-ssm"

import {getAwsConsoleUrl, getPreferredCredentials, listAccountsFiltered, sleep} from "./authentication";
import {load_template} from "./templating";
import {ListSecretsCommand, SecretsManagerClient} from "@aws-sdk/client-secrets-manager";
import {
    AthenaClient,
    GetQueryExecutionCommand,
    GetQueryResultsCommand,
    StartQueryExecutionCommand
} from "@aws-sdk/client-athena";

export async function show_secrets_view(page) {

    if (page.path === "/secrets/") {
        await show_secrets(page)
    } else {
        throw Error("not found")
    }

}

async function show_secrets(page) {
    const rootTemplate = load_template('secrets_list', 'content_holder')

    const accounts = await listAccountsFiltered(page.parameters.accountId) // list all accounts we have access to
    const regions = page.parameters.region !== undefined ? [page.parameters.region] : ["eu-west-1", "us-east-1", "eu-central-1"]
    const promises = []
    const now = Date.now()
    const foundParameterStoreEntries = []
    for (const accountId of Object.keys(accounts)) {
        // fetches all accounts in parallel
        const await_handle = getPreferredCredentials(accountId)
            .then(async function (credentials) {
                    if (credentials) {
                        for (const region of regions) {

                            // SSM Parameter store
                            const ssm_client = new SSMClient({
                                region: region,
                                credentials: credentials
                            });
                            const pFilters = [{
                                Key: "Type",
                                Values: ["SecureString"]
                            }]
                            if (page.parameters.secret != null) { // fetch one secret
                                pFilters.push({
                                    Key: "Name",
                                    Values: [page.parameters.secret]
                                })
                            }
                            const foundParameterStoreSecrets = []
                            let nextToken = undefined
                            do {
                                const response = await ssm_client.send(new DescribeParametersCommand({
                                    Filters: pFilters,
                                    NextToken: nextToken
                                }))

                                for (const secret of response.Parameters) {
                                    const ageOfSecretMilliseconds = now - secret.LastModifiedDate.getTime()
                                    secret.ageInDays = Math.ceil(ageOfSecretMilliseconds / (1000 * 60 * 60 * 24))
                                    secret.ageColor = secret.ageInDays >= 365 ? (
                                        secret.ageInDays >= (365 * 2) ? "NON_COMPLIANT" : "WARNING"
                                    ) : "COMPLIANT"
                                    rootTemplate.append("secrets_parameter_store_row", {
                                        accountId: accountId,
                                        accountName: accounts[accountId].accountName,
                                        region: region,
                                        secret: secret,
                                        refIdLastAccess: `${accountId}:${region}:${secret.Name}:lastAccess`,
                                        aws_link: await getAwsConsoleUrl(accountId, `https://eu-west-1.console.aws.amazon.com/systems-manager/parameters/${secret.Name.replaceAll('/','%252F')}/description?region=${region}&tab=Table`)
                                    })
                                    foundParameterStoreSecrets.push(secret.Name)
                                }
                                nextToken = response.NextToken
                            } while (nextToken !== undefined)

                            if (foundParameterStoreSecrets.length > 0) {
                                foundParameterStoreEntries.push({
                                    accountId: accountId,
                                    region: region,
                                    secrets: foundParameterStoreSecrets
                                })
                            }

                            // SecretsManager

                            const secretsmanager_client = new SecretsManagerClient({
                                region: region,
                                credentials: credentials
                            });

                            nextToken = undefined
                            let filters = null
                            if (page.parameters.secret != null) {
                                filters = [{
                                    Key: "name",
                                    Values: [page.parameters.secret]
                                }]
                            }
                            do {
                                let response = await secretsmanager_client.send(new ListSecretsCommand({
                                    Filters: filters,
                                    NextToken: nextToken
                                }))

                                for (const secret of response.SecretList) {
                                    if (page.parameters.secret != null && page.parameters.secret !== secret.Name) {
                                        // filter out prefix matches to parameter store and secrets manager
                                        // behave consistently (exact match only)
                                        continue
                                    }
                                    let lastChange = secret.LastChangedDate.getTime()
                                    if (secret.LastRotatedDate != null && secret.LastRotatedDate.getTime() > lastChange) {
                                        lastChange = secret.LastRotatedDate.getTime()
                                    }
                                    secret.ageInDays = Math.ceil((now - lastChange) / (1000 * 60 * 60 * 24))
                                    secret.ageColor = secret.ageInDays >= 365 ? (
                                        secret.ageInDays >= (365 * 2) ? "NON_COMPLIANT" : "WARNING"
                                    ) : "COMPLIANT"
                                    if (secret.LastAccessedDate === undefined) {
                                        secret.LastAccessedDateString = "Never"
                                    } else {
                                        secret.LastAccessedDateString = secret.LastAccessedDate.toISOString().substring(0, 10)
                                    }
                                    rootTemplate.append("secrets_secrets_manager_row", {
                                        accountId: accountId,
                                        accountName: accounts[accountId].accountName,
                                        region: region,
                                        secret: secret,
                                        aws_link: await getAwsConsoleUrl(accountId, `https://eu-west-1.console.aws.amazon.com/secretsmanager/secret?name=${secret.Name.replaceAll('/','%2F')}&region=${region}`)
                                    })
                                }
                                nextToken = response.NextToken;
                            } while (nextToken !== undefined)
                        }
                    }
                }
            ).catch((e) => {
                console.warn("Unable to fetch secrets for account " + accountId + ": " + e)
            })
        promises.push(await_handle)
    }
    await Promise.all(promises)

    const btn = document.getElementById('secrets_list_load_from_cloudtrail')
    btn.disabled = false
    btn.onclick = async function () {
        const credentials = await getPreferredCredentials('837941128681') // log-archive, cloudtrail account
        const client = new AthenaClient({credentials: credentials, region: 'eu-west-1'})
        for (const querySet of foundParameterStoreEntries) {
            console.log(`Querying cloudtrail for lastAccess for accountId=${querySet.accountId}, region=${querySet.region}`)
            for (const secret of querySet.secrets) {
                const doc = document.getElementById(`${querySet.accountId}:${querySet.region}:${secret}:lastAccess`)
                if (doc) {
                    doc.textContent = "loading..."
                }
            }

            const query =
                `WITH source
                          AS (SELECT account_id, region, date, requestparameters, eventsource, eventname, errorcode
                 FROM "AwsDataCatalog"."aws_logs"."cloudtrail_raw"
                 WHERE account_id = '${querySet.accountId}'
                   AND region = '${querySet.region}'
                   AND eventsource = 'ssm.amazonaws.com'
                   AND date >= date_format(current_timestamp - interval '90' day
                     , '%Y/%m/%d')
                   AND errorcode IS NULL
                     )
                     , all_parameters AS (
                 SELECT date, parameter_name
                 FROM source
                     CROSS JOIN UNNEST(CAST (json_extract(requestparameters, '$.names') AS ARRAY(VARCHAR))) AS t(parameter_name)
                 WHERE ( eventname LIKE 'GetParameters')
                 UNION
                 SELECT date, json_extract_scalar(requestparameters, '$.name')
                 FROM source
                 WHERE ( eventname LIKE 'GetParameter')
                     )

                SELECT parameter_name, MAX(date) AS last_access
                FROM all_parameters
                GROUP BY 1`

            client.send(new StartQueryExecutionCommand({
                QueryString: query,
                WorkGroup: 'elb_logs'
            })).then(async function (responseQueryStart) {
                let state
                do {
                    const result = await client.send(new GetQueryExecutionCommand({QueryExecutionId: responseQueryStart.QueryExecutionId}))
                    state = result.QueryExecution.Status.State
                    switch (state) {
                        case "QUEUED":
                        case "RUNNING":
                            await sleep(2000)
                            break
                        case "CANCELLED":
                            break
                        case "FAILED":
                            const err = result.QueryExecution.Status.AthenaError.ErrorMessage
                            for (const secret of querySet.secrets) {
                                const doc = document.getElementById(`${querySet.accountId}:${querySet.region}:${secret}:lastAccess`)
                                if (doc) {
                                    doc.textContent = err
                                }
                            }
                            break
                        case "SUCCEEDED":
                            let nextToken = null
                            const foundSecrets = new Set()
                            do {
                                const response = await client.send(new GetQueryResultsCommand({
                                    QueryExecutionId: responseQueryStart.QueryExecutionId,
                                    NextToken: nextToken
                                }))
                                response.ResultSet.Rows.forEach(function (row, index) {
                                    if (index > 0) {
                                        const parameterName = row.Data[0].VarCharValue
                                        foundSecrets.add(parameterName)
                                        const lastAccess = row.Data[1].VarCharValue.replaceAll('/', '-')
                                        const doc = document.getElementById(`${querySet.accountId}:${querySet.region}:${parameterName}:lastAccess`)
                                        if (doc) {
                                            doc.textContent = lastAccess
                                        }
                                    }
                                })
                                nextToken = response.NextToken
                            } while (nextToken != null)
                            for (const secret of querySet.secrets) {
                                if (!foundSecrets.has(secret)) {
                                    const doc = document.getElementById(`${querySet.accountId}:${querySet.region}:${secret}:lastAccess`)
                                    if (doc) {
                                        doc.textContent = "No usage found (90days)"
                                    }
                                }
                            }
                    }
                } while (state === "RUNNING" || state === "QUEUED")
            })
        }
    }
}
