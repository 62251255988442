import {
    CostExplorerClient,
    GetCostAndUsageCommand,
    GetCostForecastCommand,
    Granularity
} from "@aws-sdk/client-cost-explorer"
import {
    BudgetsClient,
    DescribeBudgetNotificationsForAccountCommand,
    DescribeBudgetsCommand
} from "@aws-sdk/client-budgets";
import {
    get_role_credentials,
    getAccountName,
    getPreferredCredentials,
    listAccounts,
    listAccountsFiltered
} from "./authentication";
import {load_template} from "./templating";
import {printCurrency} from "./utils";

export async function show_organization_view(page) {

    if (page.path === "/" || page.path === "") {
        await show_organization(page)
    } else {
        throw Error("not found")
    }

}

async function show_organization(page) {
    const rootTemplate = load_template('organizations_list', 'content_holder')

    const today = new Date()
    const curMonth = new Date(today)
    curMonth.setDate(1)
    const lastMonth = new Date(curMonth)
    lastMonth.setDate(0)
    lastMonth.setDate(1)
    const nextMonth = new Date(curMonth)
    nextMonth.setMonth(nextMonth.getMonth() + 1)

    const prev = lastMonth.toISOString().substring(0, 10)
    const cur = curMonth.toISOString().substring(0, 10)
    const now = today.toISOString().substring(0, 10)
    const next = nextMonth.toISOString().substring(0, 10)
    console.info(`Cost explorer time periods are: prev=${prev}, now=${now}, cur=${cur}, next=${next}`)

    //fetch accounts and populate initial table
    const accounts = await listAccountsFiltered()
    const rows = []
    for (const [account_id, account] of Object.entries(accounts)) {
        rows.push({account_id: account_id, account_name: account.accountName})
    }
    rows.sort((a, b) => {
        return a.account_name > b.account_name ? 1 : -1
    }).forEach(r => rootTemplate.append("organizations_list_row", r))

    // lazy load budgets
    for (const [account_id, account] of Object.entries(accounts)) {
        getPreferredCredentials(account_id).then(async function (credentials) {
            const client = new CostExplorerClient({credentials: credentials, region: 'us-east-1'})
            const cost = await client.send(new GetCostAndUsageCommand({
                TimePeriod: {Start: prev, End: next},
                Granularity: Granularity.MONTHLY,
                Metrics: ['UnblendedCost']
            }))
            const forecast = await client.send(new GetCostForecastCommand({
                Granularity: Granularity.MONTHLY,
                Metric: "UNBLENDED_COST",
                TimePeriod: {Start: now, End: next}
            }))
            document.getElementById(account_id + "-prev").innerText = printCurrency(parseFloat(cost.ResultsByTime[0].Total.UnblendedCost.Amount))
            document.getElementById(account_id + "-cur").innerText = printCurrency(parseFloat(cost.ResultsByTime[1].Total.UnblendedCost.Amount))
            document.getElementById(account_id + "-forecasted").innerText = printCurrency(parseFloat(forecast.Total.Amount))

            const clientB = new BudgetsClient({credentials: credentials, region: 'eu-west-1'})
            const budgets = await clientB.send(new DescribeBudgetsCommand({AccountId: account_id}))
            const monthlyBudgets = budgets.Budgets.filter(budget => budget.TimeUnit === "MONTHLY")
            if (monthlyBudgets.length > 0) {
                const value = printCurrency(parseFloat(monthlyBudgets[0].BudgetLimit.Amount))
                document.getElementById(account_id + "-budget").innerText = value

                const budgetAlarms = await clientB.send(new DescribeBudgetNotificationsForAccountCommand({AccountId: account_id}))
                const total = budgetAlarms.BudgetNotificationsForAccount.reduce((acc, budget) => acc + budget.Notifications.length, 0)
                const inAlarm = budgetAlarms.BudgetNotificationsForAccount.reduce((acc, budget) =>
                    acc + budget.Notifications.filter(n => n.NotificationState === 'ALARM').length
                , 0)
                if(inAlarm > 0) {
                    console.info(`Account ${account.accountName}: ${inAlarm}/${total} alarms are in "Alarm" state.` )
                    document.getElementById(account_id + "-budget").innerText = "🔔" + value
                }
            }


        }).catch((e) => {
            console.warn("Unable to fetch budgets for account " + account_id + ": " + e)
        })
    }
}
