import {
    ConfigServiceClient,
    DescribeComplianceByConfigRuleCommand,
    GetComplianceDetailsByConfigRuleCommand,
} from "@aws-sdk/client-config-service"
import {
    ensure_credentials,
    listAccountsFiltered, getPreferredCredentials
} from "./authentication";
import {load_template} from "./templating";
export async function show_configservice_view(page) {

    if (page.path === "/config/") {
        await show_config(page)
    } else {
        throw Error("not found")
    }

}

async function show_config(page) {
    const rootTemplate = load_template('awsconfig_resource_compliance_status', 'content_holder')

    let accounts
    if (page.parameters.accountId !== undefined) {
        accounts = await listAccountsFiltered(page.parameters.accountId)
    } else {
        accounts = await listAccountsFiltered() // list all accounts we have access to
    }

    const regions = page.parameters.region !== undefined ? [page.parameters.region] : ["eu-west-1", "us-east-1", "eu-central-1"]
    const fetches = []
    let resultCount = 0
    await ensure_credentials() // make sure the subsequent get_role_credentials don't block each other

    for (const accountId of Object.keys(accounts)) {
        // fetches all accounts in parallel
        const await_handle = getPreferredCredentials(accountId)
            .then(async function(credentials) {
                if(credentials) {
                    for(const region of regions) {
                        const client = new ConfigServiceClient({
                            region: region,
                            credentials: credentials
                        });

                        let ruleNames = []
                        let complianceTypesFilter = null
                        if (page.parameters.configRuleName != null) {
                            if(page.parameters.configRuleName.endsWith('*')) {
                                // search rule by prefix

                            } else {
                                ruleNames.push(page.parameters.configRuleName)
                            }
                        } else {
                            complianceTypesFilter = ["NON_COMPLIANT"] // if we show all rule names then we show only the non_compliants
                            let nextToken = undefined
                            do {
                                let response = await client.send(new DescribeComplianceByConfigRuleCommand({
                                    ComplianceTypes: complianceTypesFilter,
                                    NextToken: nextToken
                                }))
                                ruleNames.push(...response.ComplianceByConfigRules.map(t => t.ConfigRuleName))
                                nextToken = response.NextToken;
                            } while (nextToken !== undefined)
                        }

                        for (const ruleName of ruleNames) {
                            let nextToken = undefined
                            do {
                                let response
                                try {
                                    response = await client.send(new GetComplianceDetailsByConfigRuleCommand({
                                        ConfigRuleName: ruleName,
                                        ComplianceTypes: complianceTypesFilter,
                                        NextToken: nextToken
                                    }))
                                } catch (exception) {
                                    if(exception.name === "NoSuchConfigRuleException") {
                                        console.warn(`accountId=${accountId} region=${region} ruleName=${ruleName} exception=${exception}`)
                                        continue // ignore
                                    } else {
                                        throw exception
                                    }
                                }

                                for (const evaluationResult of response.EvaluationResults) {
                                    const resultResouceId = evaluationResult.EvaluationResultIdentifier.EvaluationResultQualifier.ResourceId

                                    // only show row if no filter is set or the filter matches
                                    if (page.parameters.resourceId == null || page.parameters.resourceId === resultResouceId) {
                                        const row = {
                                            accountId: accountId,
                                            accountName: accounts[accountId].accountName,
                                            region: region,
                                            result: evaluationResult
                                        }
                                        resultCount++
                                        rootTemplate.insert('awsconfig_resource_compliance_status_row', undefined, row)
                                    }
                                }
                                nextToken = response.NextToken;
                            } while (nextToken !== undefined)
                        }
                    }
                }
            }
        )
        fetches.push(await_handle)
    }
    await Promise.all(fetches)

    if(page.parameters.resourceId !== null && resultCount === 0) {
        throw Error("No results found for resource " + page.parameters.resourceId)
    }
}
