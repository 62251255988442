import {
    ListSubscriptionsCommand,
    ListTopicsCommand,
    SNSClient

} from "@aws-sdk/client-sns"
import {get_role_credentials, getAccountName, listAccounts, ensure_credentials} from "./authentication";
import {load_template} from "./templating";
export async function show_sns_view(page) {

    if (page.path === "/sns/") {
        await show_topics(page)
    } else {
        throw Error("not found")
    }

}

async function show_topics(page) {
    const rootTemplate = load_template('sns_topics', 'content_holder')

    let accountIds
    if (page.parameters.accountId !== undefined) {
        accountIds = [page.parameters.accountId]
    } else {
        accountIds = await listAccounts(true) // list all accounts we have access to
    }

    const regions = page.parameters.region !== undefined ? [page.parameters.region] : ["eu-west-1", "us-east-1", "eu-central-1"]
    const fetches = []
    let resultCount = 0
    await ensure_credentials() // make sure the subsequent get_role_credentials don't block each other

    for (const accountId of accountIds) {
        // fetches all accounts in parallel
        const await_handle = get_role_credentials(accountId)
            .then(async function(credentials) {
                    if(credentials) {
                        for(const region of regions) {
                            const client = new SNSClient({
                                region: region,
                                credentials: credentials
                            });

                            let nextToken = null
                            const topics = await fetchTopics(client, page.parameters.topicName)
                            do {
                                const response = await client.send(new ListSubscriptionsCommand({NextToken: nextToken}))
                                for(const sub of response.Subscriptions) {
                                    if(sub.TopicArn in topics) {
                                        topics[sub.TopicArn].push(sub)
                                    }
                                }
                                nextToken = response.NextToken;
                            } while (nextToken !== undefined)

                            for(const [topic, subscriptions] of Object.entries(topics)) {
                                for(const subscription of subscriptions) {
                                    if(subscription.SubscriptionArn === "PendingConfirmation") {
                                        subscription.Status = "PendingConfirmation"
                                    } else {
                                        subscription.Status = "Confirmed"
                                    }
                                }
                                const row = {
                                    accountId: accountId,
                                    accountName: await getAccountName(accountId),
                                    region: region,
                                    TopicName: topic.split(':').slice(-1),
                                    TopicArn: topic,
                                    Subscriptions: subscriptions
                                }
                                rootTemplate.insert('sns_topic_row', undefined, row)
                            }
                        }
                    }
                }
            ).catch((e) => {
                console.warn("Unable to fetch sns topics for account " + accountId + ": " + e)
            })
        fetches.push(await_handle)
    }
    await Promise.all(fetches)
}

async function fetchTopics(client, filterTopicName) {
    let nextToken = null
    const topics = {}
    do {
        const response = await client.send(new ListTopicsCommand({NextToken: nextToken}))
        for(const topic of response.Topics) {
            if(filterTopicName == null || topic.TopicArn.endsWith(":" + filterTopicName)) {
                topics[topic.TopicArn] = []
            }
        }
        nextToken = response.NextToken;
    } while (nextToken !== undefined)
    return topics
}
