import {CloudWatchLogsClient, DescribeLogStreamsCommand, GetLogEventsCommand} from "@aws-sdk/client-cloudwatch-logs"
import {get_role_credentials} from "./authentication";
import {load_template} from "./templating";

export async function show_cloudwatch_logs_view(page) {
    const accountId = page.parameters.accountId
    const region = page.parameters.region
    if (page.path === "/cloudwatch/logs/") {
        const rows_limit = 1000
        const log_stream_time_buffer = 4 * 60 * 60 * 1000 // 4 hours: The lastEventTime value updates on an eventual consistency basis. It typically updates in less than an hour from ingestion, but in rare situations might take longer.
        const rootTemplate = load_template('cloudwatch_logs_events', 'content_holder')
        const credentials = await get_role_credentials(accountId)
        const client = new CloudWatchLogsClient({credentials: credentials, region: region})

        const log_group = page.parameters.logGroup

        const log_group_streams_results = await client.send(new DescribeLogStreamsCommand({
            descending: true,
            logGroupName: log_group,
            orderBy: "LastEventTime"
        }))
        // with the command above we confirmed the LogGroup exits.
        const awsConsoleLink = encodeURIComponent(`https://eu-west-1.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:log-groups/log-group/${log_group.replaceAll('/', '$252F')}/log-events$3Fstart$3D-300000`)
        rootTemplate.insert("cloudwatch_logs_events_details", undefined, {
            logGroup: log_group,
            awsConsoleLink: `#openUrl?accountId=${accountId}&url=${awsConsoleLink}`,
            limit: rows_limit
        })

        let rows = []
        let next_event_stream_index = 0
        let oldest_entry = -1
        // loop until we have 1000 entries or we scanned all streams already
        while (log_group_streams_results.logStreams.length > next_event_stream_index) {
            // read all events from the stream with the most revent "lastEventTimestamp"
            const stream = log_group_streams_results.logStreams[next_event_stream_index]
            const ts = stream.lastEventTimestamp > 0 ? new Date(stream.lastEventTimestamp).toISOString() : stream.lastEventTimestamp

            // check if we reached already the rows limit AND the next logStream has no newer entries
            if (rows.length >= rows_limit && stream.lastEventTimestamp + log_stream_time_buffer < oldest_entry) {
                console.info(`Stopping at stream ${next_event_stream_index}: ${stream.logStreamName} with lastEventTimestamp=${ts}`)
                break
            }

            let next_token = null
            let added_rows = 0
            let page_index = 0
            do {
                const log_events_results = await client.send(new GetLogEventsCommand({
                    logGroupIdentifier: log_group,
                    logStreamName: stream.logStreamName,
                    startFromHead: false, // start with newest entry
                    nextToken: next_token,
                    limit: rows_limit,
                }))
                console.info(`Loaded stream ${next_event_stream_index}: ${stream.logStreamName} with lastEventTimestamp=${ts}. Page=${page_index} Rows=${log_events_results.events.length}`)

                // if have already rows_limit entries and the newest one is older than our oldest stored entry then skip this stream
                if(rows.length >= rows_limit && log_events_results.events.length > 0 && log_events_results.events[0].timestamp < oldest_entry) {
                    break
                }

                // add all rows until we have "rows_limit" entries
                for (let i = 0; i < log_events_results.events.length && added_rows < rows_limit; i++) {
                    const e = log_events_results.events[i]
                    if (oldest_entry === -1 || e.timestamp < oldest_entry) {
                        oldest_entry = e.timestamp
                    }
                    added_rows++
                    rows.push({timestamp: e.timestamp, message: e.message, logStreamName: stream.logStreamName})
                }

                // CloudWatchLogs end-of-stream definition: returns same token as input token
                if(log_events_results.nextBackwardToken === next_token) {
                    break
                }
                next_token = log_events_results.nextBackwardToken
                page_index++
            } while (added_rows < rows_limit) // no need to read next page if we reached our rows limit
            console.info(`Fetched ${rows.length} in total. oldest_entry=${new Date(oldest_entry).toISOString()}`)
            next_event_stream_index++
        }

        rows.sort((a, b) => b.timestamp - a.timestamp); // newest first
        rows = rows.slice(0, rows_limit)
        // show at most "rows_limit" entries.
        for (const item of rows) {
            rootTemplate.append("cloudwatch_logs_events_row", item)
        }
        rootTemplate.append("cloudwatch_logs_events_statistics", { row_count: rows.length })
    }
}