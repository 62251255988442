import {show_cloudformation_view} from "./cloudformation.js"
import {aws_logout, open_url} from "./authentication";
import {clear_target, load_template} from "./templating";
import {show_athena_view} from "./athena";
import {show_configservice_view} from "./config-service";
import {show_cloudwatch_logs_view} from "./cloudwatch";
import {show_organization_view} from "./organization";
import {show_sns_view} from "./sns";
import {show_secrets_view} from "./secrets";
import {show_inspector_view} from "./inspector";

let ignoreNextHashChange = undefined

window.addEventListener('hashchange', async () => {
    // ignore event if this is only an url change
    if (location.hash !== undefined && location.hash === ignoreNextHashChange) {
        ignoreNextHashChange = undefined
        return
    }

    await router();
}, false);

// main page router function - run on hash change and on load
async function router() {
    const page = parseHashUrl(location.hash);
    console.info(`New router event for path=${page.path}, parameters=`, page.parameters)

    clear_target('content_holder')
    page.notifications_template = load_template('notifications','notification_holder')

    // could be replaced by something nicer in future
    try {
        validatePageParameters(page.parameters)
        document.getElementById("loader").style.display = "block"
        if (page.path === '' || page.path === '/') {
            await show_organization_view(page);
        } else if (page.path.startsWith('/cloudformation')) {
            await show_cloudformation_view(page);
        } else if (page.path.startsWith('/config/')) {
            await show_configservice_view(page);
        } else if (page.path.startsWith('/inspector/')) {
            await show_inspector_view(page);
        } else if (page.path.startsWith('/athena/')) {
            await show_athena_view(page);
        } else if (page.path.startsWith('/cloudwatch/logs/')) {
            await show_cloudwatch_logs_view(page);
        } else if (page.path.startsWith('/sns/')) {
            await show_sns_view(page);
        } else if (page.path.startsWith('/secrets/')) {
            await show_secrets_view(page);
        } else if (page.path === 'logout') {
            await aws_logout()
        } else if (page.path === 'openUrl') {
            await open_url(page)
        } else {
            load_template('404', 'content_holder')
        }
        console.log(`Finished rendering page path=${page.path}`)
    } catch (e) {
        console.log(`Error showing page path=${page.path}`, e)
        page.notifications_template.append('error', {message: e.name + ": " + e.message})
    } finally {
        document.getElementById("loader").style.display = "none"
    }

    // render deeplink if needed
    if (page.parameters.scrollTo !== undefined) {
        const element = document.getElementById(page.parameters.scrollTo)
        if (element != null) {
            select_element(element)
            element.scrollIntoView(false)
        } else {
            console.info(`Unable to scrollTo ${page.parameters.scrollTo}: Element not found.`)
        }
    }
}

function validatePageParameters(parameters) {
    if (parameters.region != null) {
        if (parameters.region.match("\\w+-\\w+-\\d") == null) {
            throw Error("Unknown region=" + parameters.region + ". Did you mean eu-west-1?")
        }
    }
}

function select_element(element) {
    const deselectCandidates = document.getElementsByClassName("selected")
    for (const c of deselectCandidates) {
        c.classList.remove("selected")
    }
    if (element.nodeName === "TD") {
        element.parentElement.classList.add("selected")
    }
}

// expected format: #/path/to/view?param1=foo&param2=bar
function parseHashUrl(hash) {
    let result = {
        path: null,
        parameters: {}
    };

    const queryStringStart = location.hash.indexOf('?');

    if (queryStringStart === -1) {
        result.path = hash.substring(1); // everything except # is the path
    } else {
        result.path = hash.substring(1, queryStringStart);

        const queryString = hash.substr(queryStringStart + 1);
        const parts = queryString.split('&');
        for (const part of parts) {
            const keyValuePair = part.split('=', 2);
            const key = decodeURIComponent(keyValuePair[0]);
            let value = null
            if (keyValuePair.length === 2) {
                value = decodeURIComponent(keyValuePair[1]);
            }
            result.parameters[key] = value;
        }
    }

    return result;
}

export function updateUrl(parameters) {
    if (parameters != null) {
        const page = parseHashUrl(location.hash);
        Object.assign(page.parameters, parameters)
        const queryString = []
        for (const key in page.parameters) {
            queryString.push(key + "=" + encodeURIComponent(page.parameters[key]))
        }

        // change URL - but ignore this in the router
        ignoreNextHashChange = `#${page.path}?${queryString.join("&")}`
        window.location.hash = ignoreNextHashChange
    }
}

window.create_deeplink = function (element) {
    if (element.id !== undefined) {
        updateUrl({scrollTo: element.id})
        select_element(element)
    }
}

router();