import {printCurrency, printDate} from "./utils";

Handlebars.registerHelper('printDate', function (date) {
    return printDate(date)
});

Handlebars.registerHelper('uppercase', function (string) {
    return string.toUpperCase()
});

Handlebars.registerHelper('printCurrency', function (number) {
    return printCurrency(number)
});

Handlebars.registerHelper('safeFunction', function (fn) {
    return new Handlebars.SafeString("(" +
        fn.toString().replace(/\"/g, "'") + ")()");
});

export function load_template(template_id, target_id, parameters = undefined) {
    const template = document.getElementById(template_id)
    if (template == null) {
        throw Error("Unable to find element for template_id=" + template_id)
    }
    const target = document.getElementById(target_id)
    if (target == null) {
        throw Error("Unable to find element for target_id=" + target_id)
    }


    if (parameters === undefined) {
        const clonedTemplate = template.content.cloneNode(true)
        target.replaceChildren(clonedTemplate)
        return new HtmlTemplate(target)
    } else {
        const html = Handlebars.compile(template.innerHTML);
        target.innerHTML = html(parameters)
        // we cannot return a reference here, all template variables have been resolved already.
    }
}

export function clear_target(target_id) {
    const target = document.getElementById(target_id)
    if (target == null) {
        throw Error("Unable to find element for target_id=" + target_id)
    }
    target.innerHTML = '';
}

class HtmlTemplate {

    constructor(rootElement) {
        this.rootElement = rootElement
    }

    children = []

    append(templateClasses, parameters = undefined) {
        const htmlElements = this.rootElement.getElementsByClassName(templateClasses)
        if (htmlElements.length === 0) {
            throw Error("No template found for classes " + templateClasses)
        }
        const htmlElement = htmlElements[0]
        const source = htmlElement.innerHTML;
        if (parameters === undefined) {
            htmlElement.parentElement.insertAdjacentHTML('beforeend', source)
            return new HtmlTemplate(htmlElement.parentElement.lastElementChild)
        } else {
            const template = Handlebars.compile(source);
            htmlElement.parentElement.insertAdjacentHTML('beforeend', template(parameters))
            return null
        }
    }

    insert(templateClasses, insertAfter = undefined, parameters = undefined) {
        const htmlElements = this.rootElement.getElementsByClassName(templateClasses)
        if (htmlElements.length === 0) {
            throw Error("No template found for classes " + templateClasses)
        }
        const htmlElement = htmlElements[0]
        const source = htmlElement.innerHTML;

        if (insertAfter === undefined) {
            insertAfter = htmlElement // insert after template tag by default
        }
        if (parameters === undefined || parameters.length === 0) {
            insertAfter.insertAdjacentHTML('afterend', source)
            //TODO: this assumes we have a root node in this fragment. Better solution: support list of root nodes
            return new HtmlTemplate(insertAfter.nextElementSibling)
        } else {
            const template = Handlebars.compile(source);
            insertAfter.insertAdjacentHTML('afterend', template(parameters))
            //TODO: this assumes we have a root node in this fragment. Better solution: support list of root nodes
            return null
        }
    }
}

export function remove_children(element_id, tagname) {
    const element = document.getElementById(element_id)
    if (element == null) {
        throw Error("Unable to find element with id=" + element_id)
    }
    const virtualListOfElements = element.getElementsByTagName(tagname)
    while (virtualListOfElements.length > 0) {
        element.removeChild(virtualListOfElements.item(0))
    }
}